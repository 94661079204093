.seeArea .el-radio-group {
  display: flex;
  flex-direction: column;
}
.seeArea .el-radio-group .el-radio :last-child {
  margin-top: 1rem;
}
.searchbox {
  padding: 0;
}
.cascader {
  position: relative;
  width: 100%;
  z-index: 10;
}
